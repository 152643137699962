<template>
    <div>
       
        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cliente"
                    :loading="loadingClients"
                    :valueInput="values.client_id"
                    :rule="rules.required"
                    :items="serverItems.clients"
                    :errorMessages="serverErrors.client_id"
                    @valueChange="(v) => {values.client_id = v; delete serverErrors.client_id;delete serverErrors.client;delete serverErrors.client_id}"
                    data-cy="client_id-input"
                    :disabled="edit"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2" cols="12" sm="6">
                <textFieldForm 
                    @valueChange="(v) => {values.work = v; delete serverErrors.work; delete serverErrors.name}" 
                    :valueInput="values.work"
                    :rule="[...rules.required]" 
                    label="Obra"
                    :errorMessages="serverErrors.work||serverErrors.name"
                    data-cy="work-input"
                   :disabled="edit"
                ></textFieldForm>
            </v-col>

             <v-col  class="py-2" cols="12" sm="6">
                <datePickerForm
                    label="Fecha"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                    data-cy="date-input"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2 mb-5">
                <textAreaForm
                    @valueChange="(v) => {values.conditions = v}" 
                    :valueInput="values.conditions" 
                    :rule="[]" 
                    label="Condiciones"
                    :errorMessages="serverErrors.conditions"
                   
                    data-cy="conditions-input"
                ></textAreaForm>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                <p class="mb-0 pb-0">Productos</p>
            </v-col>
            <v-col class="text-right">
                <v-btn
                text
                @click="() => {showProductForm = !showProductForm}"
                color="primary"
                class="font-weight-bold"
                data-cy="showProductForm-btn"
                >Crear Nuevo</v-btn>
                <!-- <secondary-button data-cy="cancel-btn" :props="addBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button> -->
            </v-col>
        </v-row>

        <v-row v-if="showProductForm">
            <v-col>
                <v-card elevation="0" outlined class="px-3 py-3">
                   <v-row>
                    <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-5" >
                        <p class="mb-0 pb-0">Nuevo Producto</p>
                    </v-col>
                   </v-row>
                    <v-form ref="formProduct" @submit.prevent="saveProduct">
                        <v-row>
                            <v-col class="py-2">
                                <textFieldForm 
                                    @valueChange="(v) => {form.name = v; delete serverErrors.name; delete serverErrors.slug}" 
                                    :valueInput="form.name" 
                                    :rule="[...rules.required]" 
                                    label="Nombre"
                                    :errorMessages="serverErrors.name||serverErrors.slug"
                                    data-cy="name-input"
                                ></textFieldForm>
                            </v-col>
                            <v-col class="py-2 mb-5">
                                <textFieldForm
                                    @valueChange="(v) => {form.price = v}" 
                                    :valueInput="form.price" 
                                    :rule="rules.cost" 
                                    label="Precio"
                                    :errorMessages="serverErrors.price"
                                    :prefix="(form.price == '' || form.price == null) ? '' : '$'"
                                    data-cy="price-input"
                                ></textFieldForm>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row>
                        <v-col class="text-right">
                            <secondary-button data-cy="productFormcancel-btn" :props="cancelNewProductBtnData" class="mr-2"></secondary-button>
                            <primary-button data-cy="productFormaccept-btn" :props="saveNewProductBtnData" class=""></primary-button>  
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Producto"
                    :loading="loadingProducts"
                    :valueInput="productSelected"
                    :rule="[]"
                    :items="serverItems.products"
                    :returnObject="true"
                    @valueChange="(v) => {productSelected = v;}"
                    data-cy="productSelected-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="text-right">
                <v-btn
                text
                @click="addProduct()"
                class="font-weight-bold"
                data-cy="addProduct-btn"
                >Agregar</v-btn>
                <!-- <secondary-button data-cy="cancel-btn" :props="addBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button> -->
            </v-col>
        </v-row>
        

        <!-- table -->
        <v-row>
            <v-col class="py-2">
                <table-items data-cy="table-items" :props="tableProps" @methodHandler="method_handler" :items="values.products">
                    
                    <template v-slot:item.quantity="props" >

                        <div class="table-item">
                            <textFieldForm 
                                @valueChange="(v) => {props.item.quantity = v; delete serverErrors.quotation_details}" 
                                :valueInput="props.item.quantity" 
                                :rule="rules.cost" 
                                :disabled="props.item.disabled"
                                data-cy="table-quantity-input"
                                
                            ></textFieldForm>
                        </div>
                        
                    </template>

                    <template v-slot:item.price="props" >

                        <div class="table-item">
                            <textFieldForm 
                                @valueChange="(v) => {props.item.amount = v; delete serverErrors.quotation_details}" 
                                :valueInput="props.item.amount" 
                                :rule="[...rules.cost]" 
                                :disabled="props.item.disabled"
                                data-cy="table-amount-input"
                                
                            ></textFieldForm>
                        </div>
                        
                    </template>

                    <template v-slot:item.subtotal="props" >

                        <div class="table-item">
                          <span data-cy="table-subtotal-input">{{parseFloat(props.item.amount*props.item.quantity).toFixed(2)}}</span>
                        </div>
                        
                    </template>
                    
                </table-items>
                
            </v-col>
            
        </v-row>
        <span class="red--text caption" v-if="serverErrors[`quotation_details`]">{{serverErrors[`quotation_details`]}}</span>
      
      

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            showProductForm:false,
            loadingClients: false,
            loadingProducts: false,
            productSelected: "",
           
            form: {
                name: "",
                description:"",
                price: "",
            },
            cancelNewProductBtnData: {
                text: "Cancelar",
                icon: "",
                to: "",
                block:false,
                click: () => {this.showProductForm = !this.showProductForm}
            },
            saveNewProductBtnData: {
                text: "Guardar",
                icon: "",	
                click: this.saveNewProduct,
                block:false,
                loading: false,
            },
            actions: [
				
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItem',
                    dataCy: "delete-link"
				},
			],
            tableProps: {
				headers: [
                     {
						text: 'Producto',
						align: 'left break-words',
						value: 'name',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},
					{
						text: 'Cantidad',
						align: 'center',
						value: 'quantity',
                        width: "20%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Costo',
						align: 'center',
						value: 'price',
                        width: "20%",
						sortable: false,
						class: 'table-b-border black--text'
                    },
                    {
						text: 'Subtotal',
						align: 'center',
						value: 'subtotal',
                        width: "20%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
               
                cost: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
                quantity: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
				
				
				
            },

            serverItems:{
                clients: [],
                products: []
            }

            

          
        }
    },
   
    mounted(){
        this.getClients();
        this.getProducts();
        this.loadProducts();
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        clear()
        {
            this.values.products = [];
        },
        getClients()
        {
            this.loadingClients = true;
            this.$api.client.getSelect()
                .then((resp) => {
                    this.serverItems.clients = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingClients = false;
                })
            ;
        },

        getProducts()
        {
            this.loadingProducts = true;
            this.$api.product.getSelect()
                .then((resp) => {
                    this.serverItems.products = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingProducts = false;
                })
            ;
        },

        loadProducts()
        {
            this.values.products = this.values.products.map((item,i) => {
                // x['parameters'] = { id: x.slug, name: x.sku, index: i};
                
              
               
                let newProd = {
                    actions: JSON.parse(JSON.stringify(this.actions)),
                    parameters: { id: item.product_id, name: item.product_name, index: 99},
                    id: item.product_id,
                    name: item.product_name,
                    quantity : item.quantity,
                    amount: item.amount,
                }
                return newProd;
            })
        },

        addProduct()
        {
            if(!("value" in this.productSelected))
                return;

            let found = this.values.products.find(fItem => fItem.id == this.productSelected.value)
            if(found != undefined)
                return;

            let item = this.productSelected;
            let newProd = {
                actions: JSON.parse(JSON.stringify(this.actions)),
                parameters: { id: item.value, name: item.text, index: 99},
                id: item.value,
                name: item.text,
                quantity : 1,   //item.quantity,
                amount: item.price,
            }

            this.values.products.push(newProd);
        },

        deleteItem(item)
        {
            let index = this.values.products.findIndex(prod => prod.id == item.id);

            console.log(index, item);
            this.values.products.splice(index, 1);
        },


       

        validate () {
			return this.$refs.formProduct.validate();
            // return true;
		},
        saveNewProduct(){
            
            console.log(this.form);
            
            if(this.validate()){
                
                // let form = this.prepareRequest(this.form);
                this.form.description = this.form.name;
                this.saveNewProductBtnData.loading = true;
                this.$api.product.store(this.form)
                    .then((resp) => {
                        this.serverItems.products.push({text: resp.data.name, value: resp.data.id, price: resp.data.price});
                        this.productSelected = {text: resp.data.name, value: resp.data.id, price: resp.data.price};
                        this.addProduct();
                        this.productSelected = "";
                        this.$refs.formProduct.reset();
                    })
                    .catch((error) => {
                        console.log(error)
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("error" in error.response.data)){
                                    if(error.response.data.error instanceof Object){
                                        Object.keys(error.response.data.error).forEach((x) => {
                                            
                                               
                                            console.log(x);
                                            this.serverErrors = error.response.data.error;
                                            error.response.data.error[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.error)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                        console.log(this.serverErrors)
                       
                    })
                    .finally(() => {
                          this.saveNewProductBtnData.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario incompleto`);
            }
            // eslint-disable-next-line no-console
        },

    }
}
</script>

<style>

</style>